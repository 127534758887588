import classnames from 'classnames';
import { FaFlag, FaBomb } from 'react-icons/fa';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './tile.scss';

class Tile extends Component {

  render() {
    const { details, onClick, onContextMenuClick } = this.props;
    const tileStyle = classnames('tile', {
        revealed: details.isRevealed,
        explosive: details.isMine,
    });

    let icon = null;
    if (!details.isRevealed)
      icon = details.hasFlag ? <FaFlag color="red" /> : null;
    else if (details.neighbouringMines > 0)
      icon = details.neighbouringMines;
    else if (details.hasMine)
      icon = <FaBomb color="black" />;


    return (
      <div
        onClick={onClick}
        className={tileStyle}
        onContextMenu={onContextMenuClick}
      >
        {icon}
      </div>
    );
  }
}

Tile.propTypes = {
  currentState: PropTypes.object,
  onPressed: PropTypes.func,
  cMenu: PropTypes.func
}

export default Tile;

import { isEqual } from 'lodash'

/**
 * Creates a new [][] array with tiles for the playing board
 * @param {number} height - The playing board height
 * @param {number} width - The playing board width
 * @retun {matrix} - The created matrix
 */
export function createBoardMatrix(height, width) {
  let matrix = [];

  for (let i = 0; i < height; i++) {
    matrix.push([]);
    for (let j = 0; j < width; j++) {
      let tile = {
        id: generateGuid(),
        coordinates:{
          x: i,
          y: j,
        },
        details:{
          hasFlag: false,
          isRevealed: false,
          hasMine: false,
          isSafe: false,
          neighbouringMines: 0,
        }
      };
      matrix[i][j] = tile;
    }
  }
  return matrix;
}

/**
 * Randomly distributes mines across the matrix
 * @param {[][]} matrix - The playing board matrix
 * @retun {matrix} - The updated matrix
 */
export function distributeMines(matrix, mines) {
 let rx, ry, minesPlanted = 0;
 const matrixWidth = matrix.length;
 const matrixHeight = matrix[0].length;

 while (minesPlanted < mines) {
   rx = getRandomInt(matrixWidth);
   ry = getRandomInt(matrixHeight);
   if (!matrix[rx][ry].details.hasMine) {
     matrix[rx][ry].details.hasMine = true;
     minesPlanted++;
   }
 }

 return matrix;
}

/**
 * Assigns a number value (neighbouringMines) to each tile, defining how many neighbouring
 * tiles have a mine
 * @param {[][]} matrix - The playing board matrix
 * @retun {matrix} - The updated matrix
 */
export function assignNeighbourCount(matrix) {
   const matrixWidth = matrix.length;
   const matrixHeight = matrix[0].length;

   for (let i = 0; i < matrixHeight; i++) {
     for (let j = 0; j < matrixWidth; j++) {
       if (!matrix[i][j].details.hasMine) {
         let neighbouringMines = 0;
         const surrondingTiles = findNeighbours(matrix[i][j].coordinates.x, matrix[i][j].coordinates.y, matrix);
         surrondingTiles.forEach(tile => tile.details.hasMine && neighbouringMines++);
         if (neighbouringMines === 0) {
           matrix[i][j].details.isSafe = true;
         }
         matrix[i][j].details.neighbouringMines = neighbouringMines;
       }
     }
   }

   return matrix;
 };

 /**
  * Flips all of the tiles to be visible in the matrix
  * @param {[][]} matrix - The playing board matrix
  * @retun {matrix} - The updated matrix
  */
export function showBoard(matrix) {
   let updatedData = matrix;
   updatedData.forEach(row => {
     row.forEach(tile => tile.details.isRevealed = true);
   });
   return (updatedData);
 }

 /**
  * A tile has been pressed by the user, updates the propers variables
  * & shows the tiles that correspond
  * @param {[][]} matrix - The playing board matrix
  * @retun {matrix} - The updated matrix
  */
 export function tilePressed(matrix, coordinates) {
    let {
      x,
      y
    } = coordinates;

    matrix[x][y].details.hasFlag = false;
    matrix[x][y].details.isRevealed = true;

    if (matrix[x][y].details.isSafe) {
      matrix = showTile(x, y, matrix);
    }

    return matrix;
  }

/**
 * Given a matrix, it validates if there is a win on the board
 * by checking to see if the mine tiles that are active on the board
 * equate to the flag tiles that are active on the board
 * @param {[][]} matrix - The playing board matrix
 * @retun {boolean} - A boolean defining if the mines array is equal to the flags array
 */
 export function isFlagWin(matrix){
   return isEqual(getMines(matrix), getFlags(matrix));
 }

 /**
  * Returns the number of mine tiles given a matrix
  * @param {[][]} matrix - The playing board matrix
  * @retun {number} - The number of mine tiles
  */
 export function getMineCount(matrix) {
   return getMines(matrix).length;
 }

 /**
  * Returns the number of flagged tiles given a matrix
  * @param {[][]} matrix - The playing board matrix
  * @retun {number} - The number of flagged tiles
  */
 export function getFlagCount(matrix) {
   return getFlags(matrix).length;
 }

 /**
  * Returns the number of pristine tiles given a matrix
  * @param {[][]} matrix - The playing board matrix
  * @retun {number} - The number of pristine tiles
  */
 export function getPristineTileCount(matrix) {
   return getPristineTiles(matrix).length;
 }

//MARK - Private Helper Functions

/**
 * Returns back a random number based on the max
 * @param {number} max - Max number, exclusive
 * @return {number}
 */
 function getRandomInt(max) {
   return Math.floor(Math.random() * Math.floor(max));
 }

 /**
  *  Recursively finds and shows the tile that has been specified and
  *  the neighbouring tiles that have not been flagged or have a mine
  * @param {[][]} matrix - The playing board matrix
  * @retun {[][]} matrix - The updated playing board matrix with the tiles revealed
  */
 function showTile(x, y, matrix) {
    let neighbours = findNeighbours(x, y, matrix);
    neighbours.forEach(tile => {
      let coordinates = tile.coordinates;
      let details = tile.details;
      if (!details.hasFlag && !details.isRevealed
        && (details.isSafe || !details.hasMine)) {
          matrix[coordinates.x][coordinates.y].details.isRevealed = true;
          details.isSafe && showTile(coordinates.x, coordinates.y, matrix);
      }
    });
    return matrix;
  }

/**
 * Finds and returns an array of tiles that have mines
 * @param {[][]} matrix - The playing board matrix
 * @retun {[tile]} - An array with the mine tiles
 */
function getMines(matrix) {
  let mines = [];

  matrix.forEach(row => {
    mines = mines.concat(row.filter(tile => tile.details.hasMine));
  });

  return mines;
}

/**
 * Finds and returns an array of tiles that have not been flagged (right clicked)
 * @param {[][]} matrix - The playing board matrix
 * @retun {[tile]} - An array with the flagged tiles
 */
function getFlags(matrix) {
  let flags = [];

  matrix.forEach(row => {
    flags = flags.concat(row.filter(tile => tile.details.hasFlag));
  });

  return flags;
}

/**
 * Finds and returns an array of tiles that have not been clicked on
 * @param {[][]} matrix - The playing board matrix
 * @retun {[tile]} - An array with the pristine tiles
 */
function getPristineTiles(matrix) {
  let pristine = [];

  matrix.forEach(row => {
    pristine = pristine.concat(row.filter(tile => !tile.details.isRevealed));
  });

  return pristine;
}

/**
 * Finds neighbouring tiles
 * @param {number} row - The employee who is responsible for the project.
 * @param {number} col - The name of the employee.
 * @param {[tile][tile]}
 */
function findNeighbours(row, col, matrix) {
  const neighbours = [];
  const matrixWidth = matrix.length;
  const matrixHeight = matrix[0].length;

  //ABOVE
  row > 0 && neighbours.push(matrix[row - 1][col]);

  //BOTTOM RIGHT
  row < matrixHeight - 1 && col < matrixWidth - 1 && neighbours.push(matrix[row + 1][col + 1]);

  //ABOVE RIGHT
  row > 0 && col < matrixWidth - 1 && neighbours.push(matrix[row - 1][col + 1]);

  //RIGHT
  col < matrixWidth - 1 && neighbours.push(matrix[row][col + 1]);

  //DOWN
  row < matrixHeight - 1 && neighbours.push(matrix[row + 1][col]);

  //LEFT
  col > 0 && neighbours.push(matrix[row][col - 1]);

  //ABOVE LEFT
  row > 0 && col > 0 && neighbours.push(matrix[row - 1][col - 1]);

  //BOTTOM LEFT
  row < matrixHeight - 1 && col > 0 && neighbours.push(matrix[row + 1][col - 1]);

  return neighbours;
}

/**
 * Generates a GUID
 * @return {string}
 */
function generateGuid() {
   var S4 = function() {
      return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
   };
   return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

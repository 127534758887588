import React from 'react';
import './page-container.scss';

const PageContainer = props => (
  <div className="container">
    {props.children}
  </div>
);

export default PageContainer;

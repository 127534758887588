import classnames from 'classnames';
import React from 'react';
import Board from '../../components/Board';
import PageContainer from '../../components/PageContainer';
import './sweeper.scss';

const levels = {
  easy : {
    height: 10,
    width: 10,
    mines: 5,
  },
  medium : {
    height: 15,
    width: 15,
    mines: 30,
  },
  hard: {
    height: 20,
    width: 20,
    mines: 100,
  }
};

class Sweeper extends React.Component {
  state = {
    level: 'easy',
    board: levels['easy'],
  };

  handleDifficulityChange(level) {
    this.setState({ board: levels[level], level });
  }

  render() {
    const containerStyle = classnames('minesweeperContainer', this.state.level);
    return (
      <PageContainer>
        <div className={containerStyle}>
          <div className="dificultyOptions">
            <button onClick={()=> this.handleDifficulityChange('easy')}>Easy</button>
            <button onClick={()=> this.handleDifficulityChange('medium')}>Kinda Hard</button>
            <button onClick={()=> this.handleDifficulityChange('hard')}>Super Hard</button>
          </div>
          <Board
            level={this.state.level}
            height={this.state.board.height}
            width={this.state.board.width}
            mineCount={this.state.board.mines}
          >
          </Board>
        </div>
      </PageContainer>
    )
  }
}

export default Sweeper;

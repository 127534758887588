import classnames from 'classnames';
import { emojisplosions } from "emojisplosion";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Tile from '../Tile';
import {
  assignNeighbourCount,
  createBoardMatrix,
  isFlagWin,
  distributeMines,
  getPristineTileCount,
  tilePressed,
  showBoard,
} from './Board.service';
import './board.scss';

const GAME_STATUS = {
  IN_PLAY: 'IN_PLAY',
  LOST: 'LOST',
  WON: 'WON',
};

class Board extends Component {
 state = {
    mineCount: this.props.mineCount,
    gameStatus: GAME_STATUS.IN_PLAY,
    boardData: this.loadBoard(this.props.height, this.props.width, this.props.mineCount),
 };

 loadBoard(height, width, mineCount) {
    let matrix = createBoardMatrix(height, width);
    matrix = distributeMines(matrix, mineCount);
    matrix = assignNeighbourCount(matrix);
    return matrix;
 }

  wonGame(){
    const { cancel } = emojisplosions();
    setTimeout(cancel, 10000);
    this.setState({
      boardData: showBoard(this.state.boardData),
      gameStatus: GAME_STATUS.WON,
    });
  }

  lostGame() {
    this.setState({
      boardData: showBoard(this.state.boardData),
      gameStatus: GAME_STATUS.LOST,
    });
  }

  handleReset (){
     this.setState({
       mineCount: this.props.mineCount,
       gameStatus: GAME_STATUS.IN_PLAY,
       boardData: this.loadBoard(this.props.height, this.props.width, this.props.mineCount),
     });
  }

  handleTileClicked(coordinates) {
       const { x, y } = coordinates;
       let {
         boardData,
         mineCount,
         gameStatus,
       } = this.state;
       const tile = this.state.boardData[x][y];

      if (tile.details.isRevealed
             || tile.details.hasFlag) return;

      if (tile.details.hasMine){
        this.lostGame();
        return;
      }

      boardData = tilePressed(boardData, coordinates);

      if (getPristineTileCount(boardData) === mineCount) {
        this.wonGame();
        return;
      }

      this.setState({
        ...this.state,
        boardData,
        gameStatus,
      });
   }

  handleTileContextMenuClicked(e, coordinates) {
     e.preventDefault();
     const { x, y } = coordinates;
     let {
       mineCount,
       gameStatus,
       boardData,
     } = this.state;
     const currentTile = boardData[x][y];

     if (currentTile.details.isRevealed) return;

     if (currentTile.details.hasFlag) {
         currentTile.details.hasFlag = false;
         mineCount++;
     } else {
         currentTile.details.hasFlag = true;
         mineCount--;
     }

     boardData[x][y] = currentTile;

     if (mineCount === 0 && isFlagWin(boardData)) {
          this.wonGame();
     }

     this.setState({
       ...this.state,
       gameStatus,
       boardData,
       mineCount,
     });
  }

  renderBoard(matrix) {
    return matrix.map(row => {
      return row.map(tile => {
        return (
            <Tile
              key={tile.id}
              onClick={() => this.handleTileClicked(tile.coordinates)}
              details={tile.details}
              onContextMenuClick={(e)=> this.handleTileContextMenuClicked(e, tile.coordinates)}
            />
        );
      })
    });
  }

  componentDidUpdate(prevProps) {
    if(prevProps !== this.props) {
        this.setState({
          mineCount: this.props.mineCount,
          gameStatus: this.props.gameStatus,
          boardData: this.loadBoard(this.props.height, this.props.width, this.props.mineCount),
        });
     }
  }

  render() {
    const boardStyles = classnames('boardContainer', this.props.level);
    return (
        <div className={boardStyles}>
          <div className="boardHeader">
            <div className="controlContainer ">
              <div className="counter right">
                {this.state.mineCount}
                <br/>
                Mines
              </div>
            </div>
            <div className="controlContainer">
              <button className="reset" onClick={() => this.handleReset()}>Reset</button>
            </div>
          </div>
          <div className="lost">{ this.state.gameStatus === GAME_STATUS.LOST && this.state.gameStatus}</div>
          <div className="boardRow">
            {this.renderBoard(this.state.boardData)}
          </div>
      </div>
    );
  }
}

export default Board;

Board.propTypes = {
  level: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  mineCount: PropTypes.number.isRequired
}
